.main-wrapper {
    background-color: rgba(255, 204, 153, 0.5);
    background-image: url('../image/background.png');
    background-size: cover;
    background-position: center;
  
    display: flex;
    justify-content: center;
    margin-top: 2rem;

    position: relative;
    padding: 1rem;
  }


.contact-container::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 204, 153, 0.3);
    pointer-events: none;
}

.contact-box {
    display: flex;
    justify-content: space-around;
    gap: 1rem;
    width: 80%;
    padding: 3rem;
    border: 1px solid #ddd;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    background-color: #fff;
    
}

.box-component img {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    object-fit: cover;
}

.box-component {
    padding-top: 1rem;
    text-align: center;
}

.email-container a {
    text-decoration: none;
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.social {
    margin-top: 1rem;
}

.LinkedIn, 
.Github {
    padding-right: 0.5rem;
}

.contact-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2px;
    height: 300px;
    background: linear-gradient(to bottom right, #B3B6F2, #7E5EF2);
    transition: background 0.5s ease;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* elevation 8 equivalent */
    border-radius: 20px;
}

@media (min-width: 600px) {
    .contact-box {
        flex-direction: row;
    }
}
