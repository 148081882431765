#home {
    margin-top: 6rem;
}

.download-container {
    display: inline-block;

    padding: 10px 20px;
    margin: 25px 0px;

    background-color:#A38DF2;
    color:white;

    text-align: center;
    text-decoration: none;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}


.download-container:hover {
    background-color: #7E5EF2; 
}

h1 {
    margin: 1rem 0;
    font-size: 6rem;

    text-shadow: 0 15px 15px rgba(0, 0, 0, .16)
}

h2 {
    color: #5F8C1B;
}

p {
    margin: 1rem 0;
}


  