#about {
    text-align: left;
    padding: 50px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .timeline-header {
    margin-bottom: 20px;
    color: #ffffff;
    background-color: #A38DF2;
    font-size: 22px;
    padding: 10px 20px;
    border-radius: 8px;
    text-align: center;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  h3 {
    font-size: 24px;
    margin-bottom: 5rem;
    color: #333;
    text-align: center;
    font-weight: 400;
    letter-spacing: 1px;
  }
  
  .timeline-container {
    display: flex;
    align-items: flex-start;
    width: 100%;
    justify-content: flex-start;
  }
  
  .timeline {
    display: flex;
    flex-direction: column;
    margin-right: 40px;
    position: relative;
  }

  .timeline-group {
    margin-bottom: 2rem; 
  }
  
  .timeline::before {
    content: '';
    position: absolute;
    top: 10px;
    bottom: 0;
    left: 10px;
    width: 2px;
    background-color: #A38DF2;
  }
  
  .timeline-item {
    cursor: pointer;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    position: relative;
    padding-left: 25px;
    transition: transform 0.3s ease;
    
  }
  
  .timeline-item.active {
    transform: scale(1.1);
  }
  
  .timeline-marker {
    width: 20px;
    height: 20px;
    background-color: #A38DF2;
    border-radius: 50%;
    margin-right: 10px;
    position: absolute;
    left: 0;
  }
  
  .timeline-label {
    font-size: 16px;
    color: #333;
    font-weight: 500;
  }
  
  .content-display {
    max-width: 800px;
    flex-grow: 1;
  }
  
  .narrative-section {
    display: none;
    transition: opacity 0.5s ease;
  }
  
  .narrative-section.active {
    display: block;
    opacity: 1;
  }
  
  .section-image {
    width: 100%; 
    height: auto; 
    max-height: 300px; 
    object-fit: cover; 
    border-radius: 10px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .section-text {
    margin-top: 20px;
  }
  
  .section-text h2 {
    font-size: 20px;
    font-weight: 500;
    color: #A38DF2;
    margin-bottom: 15px;
  }
  
  .section-text p {
    font-size: 16px;
    font-weight: 300; 
    color: #555;
    line-height: 1.6; /* Improve readability */
  }
  
  .section-text h4 {
    font-size: 18px;
    font-weight: 500;
    color: #A38DF2;
    margin-top: 20px;
  }


  @media (max-width: 768px) {
    .timeline-header {
      font-size: 15px;
    }

    h3 {
        font-size: 20px;
      }
    
      .timeline-label {
        font-size: 14px;
      }
    
      .section-text h2 {
        font-size: 18px;
      }
    
      .section-text p {
        font-size: 14px;
      }
    
      .section-text h4 {
        font-size: 16px;
      }
  }
  