#work-experience {
    text-align: left;
    padding: 50px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  h3 {
    font-size: 24px;
    margin-bottom: 5rem;
    color: #333;
    text-align: center;
    font-weight: 400;
    letter-spacing: 1px;
  }
  
  .experience-container {
    width: 100%;
    max-width: 800px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .experience-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 40px;
    text-align: center;
    width: 100%; /* Ensure the item takes up the full width of the container */
  }
  
  .experience-image {
    width: 300px; 
    height: 100px; 
    object-fit: cover;
    border-radius: 10px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
  }
  .image-container {
    display: flex; 
    justify-content: center; 
    align-items: center; 
    margin: 20px auto; /* Adds space around the container */
  }
  .experience-text {
    width: 100%;
  }
  
  .experience-text h2 {
    font-size: 20px;
    font-weight: 500;
    color: #A38DF2;
    margin-bottom: 10px;
  }
  
  .experience-text h4 {
    font-size: 18px;
    font-weight: 400;
    color: #333;
    margin-bottom: 5px;
  }
  
  .experience-text h5 {
    font-size: 16px;
    font-weight: 400;
    color: #777;
    margin-bottom: 10px;
  }
  
  .experience-text p {
    font-size: 16px;
    font-weight: 300; 
    color: #555;
    line-height: 1.6;
  }
  
  /* Responsive styling for smaller screens */
  @media (max-width: 768px) {
    h3 {
      font-size: 20px;
    }
  
    .experience-image {
      width: 100%; 
      height: auto; 
    }
  
    .experience-text h2 {
      font-size: 18px;
    }
  
    .experience-text h4 {
      font-size: 16px;
    }
  
    .experience-text h5 {
      font-size: 14px;
    }
  
    .experience-text p {
      font-size: 14px;
    }
  }
  