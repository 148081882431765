.project-paper {
    height: 100%;
    border-radius: 10px;
    overflow: hidden;
  }
  
  .project-content {
    height: 100%;
    display: flex;
    flex-direction: column;
    /* justify-content: space-between; */
  }
  
  .project-image img {
    width: 100%;
    height: auto;
    max-height: 200px;
    object-fit: cover;
  }
  
  .project-info {
    min-height: 6rem;
  }
  
  .project-technologies {
    list-style: none;
    padding: 0;
    min-height: 3rem;
  }
  
  .project-button {
    background-color: #A38DF2 !important;
    color: white;
    font-weight: bold;
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    padding: 10px 20px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    outline: none;
    width: 100%;
  }
  
  .project-button:hover {
    background-color: #896cc8 !important;
  }
  